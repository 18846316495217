export type GameLoaderType =
  | 'unity6'
  | 'unity2023'
  | 'unity2022'
  | 'unity2021'
  | 'unity2020'
  | 'unity2019'
  | 'unity2018'
  | 'unity2017'
  | '5.6.x' // we used to store Unity 2017, 2018 and 2019 as 5.6.x also, so in the database there may be some 5.6.x games that are not Unity 5.6.x
  | '5.4.x'
  | 'iframe'
  | 'html5'
  | 'external'
  | 'swfviewer'
  | 'ruffle'
  | 'flash' // deprecated, at some point we will remove it as all games are archived
  | 'fake'
  | 'error'
  // these are temporary loaders used for the migration of Unity to iframe
  | 'iframed_unity2020'
  | 'iframed_5.4.x'
  | 'iframed_5.6.x';

export const gameLoaderTypes: GameLoaderType[] = [
  'unity6',
  'unity2023',
  'unity2022',
  'unity2021',
  'unity2020',
  'unity2019',
  'unity2018',
  'unity2017',
  '5.6.x',
  '5.4.x',
  'iframe',
  'html5',
  'external',
  'swfviewer',
  'ruffle',
  'fake',
  'error',
  'iframed_unity2020',
  'iframed_5.4.x',
  'iframed_5.6.x',
];

export const UNITY_GAME_LOADERS = [
  'unity6',
  'unity2023',
  'unity2022',
  'unity2021',
  'unity2020',
  'unity2019',
  'unity2018',
  'unity2017',
  '5.6.x',
  '5.4.x',
];

/**
 * Developers can select any one of these types when creating a submission.
 * However, we prefer that developers use recent Unity versions, so hide older Unity versions behind a "show more" button
 * Keep AVAILABLE_DEVELOPER_LOADER_TYPES in sync DP and Graph.
 */
export const RECOMMENDED_LOADER_TYPES: GameLoaderType[] = ['unity6', 'unity2023', 'unity2022', 'unity2021', 'unity2020', 'iframe', 'html5'];

export const NON_RECOMMENDED_LOADER_TYPES: GameLoaderType[] = ['unity2019', 'unity2018', 'unity2017', '5.6.x'];

export const AVAILABLE_DEVELOPER_LOADER_TYPES: GameLoaderType[] = [...RECOMMENDED_LOADER_TYPES, ...NON_RECOMMENDED_LOADER_TYPES];

/** These Unity versions allow the devs to select brotli/gzip compression */
export const unityLoadersWithCompression: GameLoaderType[] = [
  'unity6',
  'unity2023',
  'unity2022',
  'unity2021',
  'unity2020',
  'unity2019',
  'unity2018',
  'unity2017',
  '5.6.x',
];

/**
 * These game loader versions display the toggles so the dev can tell us how the game saves data,
 * and also allow displaying a custom file name field, for when the game stores data in file.
 * Keep in sync DP and Graph.
 */
export const unityLoadersWithDataSave: GameLoaderType[] = [
  'unity6',
  'unity2023',
  'unity2022',
  'unity2021',
  'unity2020',
  'unity2019',
  'unity2018',
  'unity2017',
  '5.6.x',
];

/** These game loader versions display the toggles so the dev can tell us how the game saves data. */
export const loadersWithSaveDataToggles: GameLoaderType[] = ['html5', 'iframe', ...unityLoadersWithDataSave];

/**
 * These Unity versions benefit from the sitelock.
 * Keep DP and Graph in sync.
 */
export const LOADERS_WITH_SITELOCK: GameLoaderType[] = [
  'unity6',
  'unity2023',
  'unity2022',
  'unity2021',
  'unity2020',
  'unity2019',
  'unity2018',
  'unity2017',
  '5.6.x',
];

export const standaloneLoaders: GameLoaderType[] = [
  'unity6',
  'unity2023',
  'unity2022',
  'unity2021',
  'unity2020',
  'unity2019',
  'unity2018',
  'unity2017',
  '5.6.x',
  'iframe',
  'html5',
];

/** These game engines will display the iOS/Android friendly toggles */
export const MOBILE_FRIENDLY_LOADERS: GameLoaderType[] = [
  'unity6',
  'unity2023',
  'unity2022',
  'unity2021',
  'unity2020',
  'unity2019',
  'unity2018',
  'unity2017',
  '5.6.x',
  'iframe',
  'html5',
];

export function gameLoaderTypeToName(gameLoaderType: GameLoaderType): string {
  switch (gameLoaderType) {
    case 'unity6':
      return 'Unity 6';
    case 'unity2023':
      return 'Unity 2023';
    case 'unity2022':
      return 'Unity 2022';
    case 'unity2021':
      return 'Unity 2021';
    case 'unity2020':
      return 'Unity 2020';
    case 'unity2019':
      return 'Unity 2019';
    case 'unity2018':
      return 'Unity 2018';
    case 'unity2017':
      return 'Unity 2017';
    case '5.6.x':
      return 'Unity 5.6';
    case 'iframe':
      return 'IFrame';
    case 'html5':
      return 'HTML5';
    default:
      throw new Error(`Unmapped GameType: ${gameLoaderType}`);
  }
}
