import { DOCS_URL } from '../../../SideMenu/Menu';
import {
  MAX_TOTAL_FILE_SIZE_MB,
  MAX_TOTAL_FILES_COUNT,
  TOTAL_FILE_COUNT_WARNING,
  TOTAL_FILE_SIZE_DESKTOP_WARNING_MB,
  TOTAL_FILE_SIZE_MOBILE_WARNING_MB,
} from '../Submission/GameBaseForm/baseFormHelper';
import { renderHardWarning, renderSoftWarning } from '../Submission/GameBaseForm/GameBaseFormFilesAndOptions';
import { BaseFormSoftWarnings } from '../SubmitGame/submission-errors';

export function renderWarnings(limitsExceededError: boolean | undefined, softWarnings: BaseFormSoftWarnings[]) {
  return (
    <>
      {limitsExceededError && renderHardWarnings()}
      {!limitsExceededError && renderSoftWarnings(softWarnings)}
    </>
  );
}

function renderSoftWarnings(softWarnings: BaseFormSoftWarnings[]) {
  const elements: JSX.Element[] = [];

  if (softWarnings.includes('FILE_COUNT_TOO_BIG')) {
    elements.push(
      renderSoftWarning(
        `Your game has a lot of files and we might disable it in case of high load times. Reduce the number of files below ${TOTAL_FILE_COUNT_WARNING} for a better user experience.`,
      ),
    );
  }

  if (softWarnings.includes('TOTAL_FILE_SIZE_TOO_BIG_DESKTOP')) {
    elements.push(
      renderSoftWarning(
        `Your game is large and we might disable it in case of high load times. Reduce the file size under ${TOTAL_FILE_SIZE_DESKTOP_WARNING_MB}MB for a better user experience.`,
      ),
    );
  }

  if (softWarnings.includes('TOTAL_FILE_SIZE_TOO_BIG_MOBILE')) {
    elements.push(
      renderSoftWarning(
        `Your game is large and we might disable it on mobile in case of high load times. Reduce the file size under ${TOTAL_FILE_SIZE_MOBILE_WARNING_MB}MB for a better user experience.`,
      ),
    );
  }

  if (softWarnings.length > 0) {
    elements.push(
      renderSoftWarning(
        <>
          Check the Resources section from{' '}
          <a href={DOCS_URL} target="_blank" rel="noreferrer">
            our documentation
          </a>{' '}
          for your specific engine to find tips about optimizing your game.
        </>,
      ),
    );
  }

  return elements;
}

function renderHardWarnings() {
  return (
    <>
      {renderHardWarning(
        <>
          Maximum number of files: {MAX_TOTAL_FILES_COUNT}. Maximum total size: {MAX_TOTAL_FILE_SIZE_MB}MB (less than{' '}
          {TOTAL_FILE_SIZE_DESKTOP_WARNING_MB}MB recommended for best performance). Check the Resources section from{' '}
          <a href={DOCS_URL} target="_blank" rel="noreferrer">
            our documentation
          </a>{' '}
          for your specific engine to find tips about optimizing your game.
        </>,
      )}
    </>
  );
}
