import { useQuery } from '@apollo/client';
import * as React from 'react';
import { PROFILE_QUERY, ProfileQuery } from '../Content/Profile/profile-graphql';
import AccountCircle from './AccountCircle';
import { UserInfoContainer, UserInfoName, UserInfoText, UserInfoWelcome } from './UserInfo.styles';

const UserInfo: React.FunctionComponent = () => {
  const { loading, error, data } = useQuery<ProfileQuery>(PROFILE_QUERY);

  const name = !loading && !error ? data!.me.name : null;
  const welcomeString = !!name ? 'Welcome,' : 'Welcome';

  return (
    <UserInfoContainer>
      <UserInfoText sx={{ display: { xs: 'none', md: 'block' } }}>
        <UserInfoWelcome>{welcomeString}</UserInfoWelcome>
        <UserInfoName>{name}</UserInfoName>
      </UserInfoText>
      <AccountCircle />
    </UserInfoContainer>
  );
};

export default UserInfo;
