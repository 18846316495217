import { useContext } from 'react';
import { SubmissionContext } from '../Context/SubmissionProvider';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import { MAX_LOBBY_SIZE, MIN_LOBBY_SIZE, MultiplayerOptions } from './baseFormHelper';

interface Props {}

const GameMultiplayerOptions: React.FC<Props> = () => {
  const { baseForm, baseFormProblems, updateBaseForm, isNonEditable } = useContext(SubmissionContext);

  if (baseForm.gameLoaderType === null) {
    return null;
  }

  const renderIsMultiplayerCheckbox = () => {
    const onIsMultiplayerChange = (isMultiplayer: boolean) => {
      let update: MultiplayerOptions | undefined = {
        ...baseForm.multiplayerOptions,
        isMultiplayer,
      };

      if (!isMultiplayer) {
        // if unticking mutliplayer then reset all fields
        update = undefined;
      }

      updateBaseForm({
        ...baseForm,
        multiplayerOptions: update,
      });
    };

    return (
      <FormControlLabel
        label="The game is an online multiplayer game"
        checked={!!baseForm.multiplayerOptions?.isMultiplayer ?? false}
        disabled={isNonEditable}
        control={<Checkbox onChange={(e) => onIsMultiplayerChange(e.currentTarget.checked)} />}
      />
    );
  };

  const renderMultiplayerOptions = () => {
    const onSupportsInstantJoinChange = (supportsInstantJoin: boolean) => {
      updateBaseForm({
        ...baseForm,
        multiplayerOptions: {
          ...baseForm.multiplayerOptions,
          supportsInstantJoin,
        },
      });
    };

    const onHasModeratedChatChange = (hasModeratedChat: boolean) => {
      updateBaseForm({
        ...baseForm,
        multiplayerOptions: {
          ...baseForm.multiplayerOptions,
          hasModeratedChat,
        },
      });
    };

    const onMinLobbySizeChange = (minLobbySizeValue: string) => {
      const minLobbySize = parseInt(minLobbySizeValue);
      updateBaseForm({
        ...baseForm,
        multiplayerOptions: {
          ...baseForm.multiplayerOptions,
          minLobbySize: isNaN(minLobbySize) ? undefined : minLobbySize,
        },
      });
    };

    const onMaxLobbySizeChange = (maxLobbySizeValue: string) => {
      const maxLobbySize = parseInt(maxLobbySizeValue);
      updateBaseForm({
        ...baseForm,
        multiplayerOptions: {
          ...baseForm.multiplayerOptions,
          maxLobbySize: isNaN(maxLobbySize) ? undefined : maxLobbySize,
        },
      });
    };

    return (
      <>
        <Alert severity="warning">
          We have specific requirements for multiplayer games, please refer to our{' '}
          <a
            href={`${DOCS_URL}/requirements/multiplayer/`}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            documentation
          </a>{' '}
          . Submissions will be rejected if they don't comply to these requirements:
          <ul>
            <li>The game should use invite link & button functionality</li>
            <li>
              The game supports <a href={`${DOCS_URL}/sdk/html5-v3/game/#instant-multiplayer`}>IsInstantMultiplayer</a> so party leaders can
              create/join a lobby directly
            </li>
          </ul>
        </Alert>
        <FormControlLabel
          label="The game supports IsInstantMultiplayer flag"
          disabled={isNonEditable}
          control={
            <Checkbox
              checked={!!baseForm.multiplayerOptions?.supportsInstantJoin ?? false}
              onChange={(e) => onSupportsInstantJoinChange(e.currentTarget.checked)}
            />
          }
        />
        <FormControlLabel
          label="My game has chat functionality with a profanity filter and/or moderation."
          disabled={isNonEditable}
          control={
            <Checkbox
              checked={!!baseForm.multiplayerOptions?.hasModeratedChat ?? false}
              onChange={(e) => onHasModeratedChatChange(e.currentTarget.checked)}
            />
          }
        />
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <StyledBodyText variant="h3">Lobby size:</StyledBodyText>
          Min
          <StyledInput
            width={64}
            height={32}
            disabled={isNonEditable}
            value={baseForm.multiplayerOptions?.minLobbySize ?? ''}
            onChange={(e) => onMinLobbySizeChange(e.currentTarget.value)}
          />
          Max
          <StyledInput
            width={64}
            height={32}
            disabled={isNonEditable}
            value={baseForm.multiplayerOptions?.maxLobbySize ?? ''}
            onChange={(e) => onMaxLobbySizeChange(e.currentTarget.value)}
          />
        </div>
        {baseFormProblems?.includes('MULTIPLAYER_INVALID_LOBBY') && (
          <StyledBodyText color="alert60" sx={{ marginBlock: 0 }}>
            Please fill both lobby size fields correctly
          </StyledBodyText>
        )}{' '}
        {baseFormProblems?.includes('MULTIPLAYER_INVALID_LOBBY_MIN') && (
          <StyledBodyText color="alert60" sx={{ marginBlock: 0 }}>
            Minimum lobby size must be at least {MIN_LOBBY_SIZE}
          </StyledBodyText>
        )}
        {baseFormProblems?.includes('MULTIPLAYER_INVALID_LOBBY_MAX') && (
          <StyledBodyText color="alert60" sx={{ marginBlock: 0 }}>
            Maximum lobby size must be lower than {MAX_LOBBY_SIZE}
          </StyledBodyText>
        )}
      </>
    );
  };

  return (
    <>
      {renderIsMultiplayerCheckbox()}
      {baseForm.multiplayerOptions?.isMultiplayer && renderMultiplayerOptions()}
    </>
  );
};

export default GameMultiplayerOptions;
