import React from 'react';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { GameFilesUpload } from './GameFilesUpload';
import { UploadedFile } from '../../../../../../common/domain/upload';
import { UploadInstructions } from './UploadInstructions';
import TypeSelect from './TypeSelect';
import { BaseFormData, ProgressSaveType } from './baseFormHelper';
import OrientationSelector, { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import { StyledBodyText, StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledButton } from '../../../../../../common/Styleguide/Common/Button';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import withTheme, { WithTheme } from '../../../../../helpers/WithTheme';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { Add, Remove } from '@mui/icons-material';
import UnityCompressionOption from './UnityLoaderOptions/UnityCompressionOption';
import GameDataSaveOptions from './GameDataSaveOptions';
import UnitySaveFileName from './UnityLoaderOptions/UnitySaveFileName';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import withSubmission, { WithSubmission } from '../Context/withSubmission';
import { valueFromInput } from '../../../../../../common/input';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import GameMultiplayerOptions from './GameMultiplayerOptions';
import { GameLoaderType, UNITY_GAME_LOADERS } from '../../../../../../common/domain/game';
import { GAMEJAM_LINK, isGameJamTime } from '../gamejamHelper';
import { Link as MUILink } from '@mui/material';
import { renderWarnings } from '../../helpers/warningHelpers';

interface Props {
  showGameJamInForm?: boolean;
}

type _Props = Props & WithTheme & WithSubmission & RouteComponentProps;

interface State {
  instructionsDialogOpen: boolean;
  isCollapsed: boolean;
  showGameJam: boolean;
}

export function renderSoftWarning(text: string | JSX.Element) {
  return (
    <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="warning">
      {text}
    </StyledBodyText>
  );
}
export function renderHardWarning(text: string | JSX.Element) {
  return (
    <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
      {text}
    </StyledBodyText>
  );
}

class GameBaseFormFilesAndOptions extends React.Component<_Props, State> {
  constructor(props: _Props) {
    super(props);
    const submission = this.props.submission;
    const gameJamEnabled = isGameJamTime() && (!submission || submission?.isGameJam || submission?.status === 'DRAFT');
    this.state = {
      instructionsDialogOpen: false,
      isCollapsed: this.props.isNonEditable,
      showGameJam: gameJamEnabled && !!this.props.showGameJamInForm,
    };
  }

  render() {
    const { isReleased, isNonEditable, gameJamLocked } = this.props;
    const { isCollapsed } = this.state;
    return (
      <>
        {this.state.showGameJam && (
          <StyledContainer
            sx={{
              width: 900,
              m: 2,
              mt: 3,
              p: 2,
              px: 3,
            }}
          >
            <StyledHeaderText
              variant="h2"
              sx={{
                paddingY: 1,
                margin: 0,
              }}
            >
              Game Jam
            </StyledHeaderText>
            {this.renderGameJam()}
          </StyledContainer>
        )}
        <StyledContainer
          sx={{
            width: 900,
            m: 2,
            mt: 3,
            p: 2,
            px: 3,
          }}
        >
          {isReleased || gameJamLocked ? (
            this.renderReleasedGameHeader()
          ) : (
            <StyledHeaderText
              variant="h2"
              sx={{
                paddingY: 1,
                margin: 0,
              }}
            >
              Game type
            </StyledHeaderText>
          )}
          {!isNonEditable && (
            <Alert sx={{ marginBottom: 1 }} severity="warning">
              Please read{' '}
              <a href={DOCS_URL} target="_blank" rel="noreferrer" style={{ color: 'white' }}>
                our CrazyGames documentation
              </a>{' '}
              carefully before submitting a game!
            </Alert>
          )}
          {!isCollapsed && this.renderLoaderTypeSelect()}
          {!isCollapsed && this.renderGameOptions()}
          {!isCollapsed && this.renderGameLoaderOptions()}
        </StyledContainer>
        <StyledContainer
          sx={{
            width: 900,
            m: 2,
            mt: 3,
            p: 2,
            px: 3,
          }}
        >
          <StyledHeaderText
            variant="h2"
            sx={{
              paddingY: 1,
              margin: 0,
            }}
          >
            Files
          </StyledHeaderText>
          {this.renderGameFilesSelect()}
        </StyledContainer>
      </>
    );
  }

  private renderReleasedGameHeader() {
    const { isCollapsed } = this.state;
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StyledHeaderText
          variant="h2"
          sx={{
            m: 0,
          }}
        >
          Game type
        </StyledHeaderText>
        {this.props.isNonEditable && (
          <StyledBodyText sx={{ m: 0.5, flex: 1, ml: 4 }} variant="bodyLower2" color="white80">
            You can't update this section. Feel free to <Link to="/support">contact us</Link> if you have any question.
          </StyledBodyText>
        )}
        {isCollapsed ? (
          <Add
            fontSize="large"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() =>
              this.setState({
                isCollapsed: false,
              })
            }
          />
        ) : (
          <Remove
            fontSize="large"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() =>
              this.setState({
                isCollapsed: true,
              })
            }
          />
        )}
      </div>
    );
  }

  private renderLoaderTypeSelect() {
    const { gameLoaderType } = this.props.baseForm;
    const { isNonEditable, attemptedToSave, baseFormProblems } = this.props;
    const typeError = attemptedToSave && baseFormProblems?.includes('GAME_LOADER_TYPE_MISSING');
    return (
      <>
        <TypeSelect
          error={typeError}
          gameLoaderType={gameLoaderType || undefined}
          onGameLoaderTypeChange={this.handleGameLoaderTypeChange}
          disabled={isNonEditable}
          isGameJam={this.props.baseForm.isGameJam}
        />
        {typeError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please select a type`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleGameLoaderTypeChange = (gameLoaderType: GameLoaderType) => {
    this.props.updateBaseForm({
      ...this.props.baseForm,
      gameLoaderType,
      // reset APS/SDKPS data when changing engine type so we don't submit with wrong data
      progressSaveType: 'UNKNOWN',
      unitySaveFileName: '',
    });
  };

  private handleIsFullscreenableChange = (_evt: {}, isFullscreenable: boolean) => {
    this.props.updateBaseForm({ ...this.props.baseForm, isFullscreenable });
  };

  private renderGameJam() {
    const { isNonEditable, attemptedToSave, baseFormProblems } = this.props;
    const { isGameJam } = this.props.baseForm;
    const gameJamError = attemptedToSave && baseFormProblems?.includes('GAMEJAM_MISSING_LEGAL');

    const checkboxLabel = (
      <span>
        I submit my game as a candidate for the “
        <MUILink href={GAMEJAM_LINK} target="_blank">
          Crazy Web Game Jam 2024
        </MUILink>
        ”
      </span>
    );
    return (
      <>
        <FormControlLabel
          control={<Checkbox checked={isGameJam} onChange={this.handleIsGameJamChange} />}
          label={checkboxLabel}
          sx={{ color: COLORS.black[10] }}
          disabled={isNonEditable}
        />
        {gameJamError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please confirm the game jam registration`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleIsGameJamChange = (_evt: {}, isGameJam: boolean) => {
    if (isGameJam && this.props.baseForm.gameLoaderType === 'iframe') {
      this.props.updateBaseForm({ ...this.props.baseForm, isGameJam, gameLoaderType: null, iframeLink: null });
    }
    this.props.updateBaseForm({ ...this.props.baseForm, isGameJam });
  };

  private renderGameOptions() {
    const { theme, isNonEditable } = this.props;
    const { gameLoaderType, isIOSFriendly, isAndroidFriendly, isFullscreenable, hasIAP, isChromebookFriendly } = this.props.baseForm;
    const mustDisplayUnityIOsWarning = gameLoaderType && UNITY_GAME_LOADERS.includes(gameLoaderType) && isIOSFriendly;

    if (gameLoaderType) {
      return (
        <>
          <StyledBodyText variant="h3" sx={{ mt: 3, mb: 0 }}>
            Game Options
          </StyledBodyText>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: theme.spacing() }}>
            <FormControlLabel
              control={<Checkbox checked={isFullscreenable} onChange={this.handleIsFullscreenableChange} />}
              label="The game works well in fullscreen"
              sx={{ color: COLORS.black[10] }}
              disabled={isNonEditable}
            />
            <FormControlLabel
              control={<Checkbox checked={isIOSFriendly} onChange={this.handleIsIOSFriendlyChange} />}
              label="The game works well on iOS devices' mobile web browsers"
              disabled={isNonEditable}
              sx={{ color: COLORS.black[10] }}
            />
            {mustDisplayUnityIOsWarning && (
              <Alert sx={{ marginBottom: 1 }} severity="info">
                Unity games will be disabled on iOS initially. Our team will evaluate after launch and enable the game if it works well
              </Alert>
            )}
            <FormControlLabel
              control={<Checkbox checked={isAndroidFriendly} onChange={this.handleIsAndroidFriendlyChange} />}
              label="The game works well on Android devices' mobile web browsers"
              disabled={isNonEditable}
              sx={{ color: COLORS.black[10] }}
            />
            <FormControlLabel
              control={<Checkbox checked={isChromebookFriendly} onChange={this.handleIsChromebookFriendlyChange} />}
              label="The game works well on Chromebooks"
              disabled={isNonEditable}
              sx={{ color: COLORS.black[10] }}
            />
            <FormControlLabel
              control={<Checkbox checked={hasIAP} onChange={this.handleHasIAPChange} />}
              label="The game offers in-game purchases"
              disabled={isNonEditable}
              sx={{ color: COLORS.black[10] }}
            />
            {hasIAP && (
              <Alert sx={{ marginBottom: 1 }} severity="warning">
                In-game purchases are only allowed for selected games and should use CrazyGames' Xsolla account/integration. Your game might
                be rejected if this does not apply to your game.
              </Alert>
            )}
            <GameMultiplayerOptions />
          </div>
          {this.renderMobileOrientation()}
        </>
      );
    }
    return null;
  }

  private renderMobileOrientation() {
    const { isIOSFriendly, isAndroidFriendly, orientation } = this.props.baseForm;
    const { baseFormProblems, attemptedToSave, isNonEditable } = this.props;
    const orientationError = attemptedToSave && baseFormProblems?.includes('ORIENTATION_MISSING');

    return (
      (isIOSFriendly || isAndroidFriendly) && (
        <>
          <StyledBodyText variant="h3" sx={{ mt: 3, mb: 1.25 }}>
            Screen orientation on mobile <span>*</span>
          </StyledBodyText>
          <OrientationSelector
            disabled={isNonEditable}
            error={orientationError}
            defaultValue={orientation}
            onChange={this.handleOrientationChange}
          />
          <StyledBodyText sx={{ m: 0.5, height: 20 }} variant="bodyLower2" color="error">
            {orientationError && `Please select a orientation`}
          </StyledBodyText>
          <StyledBodyText variant="bodyLower" sx={{ marginY: 1.25 }} color="white30">
            Note: the orientation locking will be managed by us.
          </StyledBodyText>
        </>
      )
    );
  }

  private handleIsIOSFriendlyChange = (_evt: {}, isIOSFriendly: boolean) => {
    this.props.updateBaseForm({ ...this.props.baseForm, isIOSFriendly });
  };

  private handleIsAndroidFriendlyChange = (_evt: {}, isAndroidFriendly: boolean) => {
    this.props.updateBaseForm({ ...this.props.baseForm, isAndroidFriendly });
  };

  private handleIsChromebookFriendlyChange = (_evt: {}, isChromebookFriendly: boolean) => {
    this.props.updateBaseForm({ ...this.props.baseForm, isChromebookFriendly });
  };

  private handleHasIAPChange = (_evt: {}, hasIAP: boolean) => {
    this.props.updateBaseForm({ ...this.props.baseForm, hasIAP });
  };

  private handleOrientationChange = (orientation: string | null) => {
    this.props.updateBaseForm({ ...this.props.baseForm, orientation: orientation as GAME_ORIENTATION | null });
  };

  private renderUploadInstructions() {
    const { instructionsDialogOpen } = this.state;
    return (
      <div>
        <StyledButton onClick={this.toggleInstructions} height={22}>
          <HelpIcon />
        </StyledButton>
        <UploadInstructions open={instructionsDialogOpen} onClose={this.toggleInstructions} />
      </div>
    );
  }

  private toggleInstructions = () => {
    const { instructionsDialogOpen } = this.state;
    this.setState({
      instructionsDialogOpen: !instructionsDialogOpen,
    });
  };

  private renderGameLoaderOptions() {
    const { gameLoaderType, unity56Encoding, unitySaveFileName, progressSaveType } = this.props.baseForm;
    const { isNonEditable } = this.props;
    return (
      <>
        <GameDataSaveOptions value={progressSaveType} onChange={this.onGameDataSaveChange} isNonEditable={isNonEditable} />
        {progressSaveType === 'CUSTOM' && (
          <UnitySaveFileName
            gameLoaderType={gameLoaderType}
            fileName={unitySaveFileName}
            onChange={this.handleSaveFileNameChange}
            isNonEditable={isNonEditable}
          />
        )}
        <UnityCompressionOption
          gameLoaderType={gameLoaderType}
          unity56Encoding={unity56Encoding}
          files={this.props.baseForm.files}
          onChange={this.handleUnityEncodingChange}
          isNonEditable={this.props.isNonEditable}
        />
      </>
    );
  }

  private onGameDataSaveChange = (progressSaveType: ProgressSaveType) => {
    const updatedBaseForm: BaseFormData = { ...this.props.baseForm, progressSaveType };
    if (progressSaveType !== 'CUSTOM') {
      updatedBaseForm.unitySaveFileName = '';
    }
    this.props.updateBaseForm(updatedBaseForm);
  };

  private renderGameFilesSelect() {
    const { gameLoaderType } = this.props.baseForm;
    if (gameLoaderType === 'iframe') {
      return this.renderIframeInput();
    } else {
      return this.renderGameFilesUpload();
    }
  }

  private renderIframeInput() {
    const { iframeLink } = this.props.baseForm;
    const { baseFormProblems, attemptedToSave, isNonEditable } = this.props;
    const isHttp = !!iframeLink && iframeLink.startsWith('http://');
    const httpHelperText = isHttp
      ? 'Preview will not work for HTTP, if you support HTTPS please use that link instead.'
      : 'http(s)://example.com';

    const iframeError = (attemptedToSave && baseFormProblems?.includes('IFRAME_INVALID_URL')) || isHttp;

    return (
      <>
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
          IFrame link
        </StyledBodyText>
        <StyledInput
          required
          type="url"
          value={iframeLink ? iframeLink : ''}
          onChange={this.handleIframeLinkChange}
          error={iframeError}
          disabled={isNonEditable}
        />
        <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="black10">
          {httpHelperText}
        </StyledBodyText>
      </>
    );
  }

  private handleUnityEncodingChange = (encoding: BaseFormData['unity56Encoding']) => {
    this.props.updateBaseForm({ ...this.props.baseForm, unity56Encoding: encoding });
  };

  private handleSaveFileNameChange = (newFileName: string) => {
    this.props.updateBaseForm({ ...this.props.baseForm, unitySaveFileName: newFileName });
  };

  private handleIframeLinkChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.props.updateBaseForm({ ...this.props.baseForm, iframeLink: valueFromInput(evt) });
  };

  private renderGameFilesUpload() {
    const { theme, attemptedToSave, baseFormProblems, uploadingFilesSoftProblems, uploadingFilesHardProblems, isNonEditable } = this.props;
    const { files, gameLoaderType } = this.props.baseForm;

    const uploadMissingError =
      attemptedToSave && (baseFormProblems?.includes('HTML5_FILES_MISSING') || baseFormProblems?.includes('UNITY_FILES_MISSING'));

    const uploadingError = attemptedToSave && baseFormProblems?.includes('GAME_FILE_UPLOAD_IN_PROGRESS');

    const limitsExceededError =
      baseFormProblems?.includes('TOTAL_FILE_COUNT_EXCEEDED') ||
      baseFormProblems?.includes('TOTAL_FILE_SIZE_EXCEEDED') ||
      uploadingFilesHardProblems?.includes('TOTAL_FILE_COUNT_EXCEEDED') ||
      uploadingFilesHardProblems?.includes('TOTAL_FILE_SIZE_EXCEEDED');

    const softLimitsProblems = uploadingFilesSoftProblems ?? [];

    return (
      <>
        <div style={{ display: 'flex', margin: theme.spacing(2, 0, 1.25, 0) }}>
          <StyledBodyText variant="h3" sx={{ m: 0 }}>
            Files Upload <span>*</span>
          </StyledBodyText>
          {this.renderUploadInstructions()}
        </div>
        <GameFilesUpload
          initialFiles={files || []}
          error={uploadMissingError || uploadingError || limitsExceededError}
          gameLoaderType={gameLoaderType ? gameLoaderType : undefined}
          onUploadsStarted={this.handleUploadStarted}
          onFilesModified={this.handleUploadCompleted}
          onFilesToUploadSet={this.props.onFilesToUploadSet}
          isNonEditable={isNonEditable}
        />
        {uploadMissingError && renderHardWarning(`Please upload your files`)}
        {uploadingError && renderHardWarning(`Please wait until Game file upload is complete`)}
        {renderWarnings(limitsExceededError, softLimitsProblems)}
      </>
    );
  }

  private handleUploadStarted = () => {
    this.props.updateBaseForm({ ...this.props.baseForm, uploadInProgress: true });
  };

  private handleUploadCompleted = (files: UploadedFile[]) => {
    this.props.updateBaseForm({ ...this.props.baseForm, uploadInProgress: false, files });
  };
}

export default withTheme(withSubmission(withRouter(GameBaseFormFilesAndOptions)));
