import React from 'react';
import {
  GameLoaderType,
  gameLoaderTypeToName,
  NON_RECOMMENDED_LOADER_TYPES,
  RECOMMENDED_LOADER_TYPES,
  UNITY_GAME_LOADERS,
} from '../../../../../../common/domain/game';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { CustomSelect } from '../../../../../../common/Styleguide/Common/Select';
import { Option } from '@mui/base';
import { HALF_INPUT_WIDTH } from '../UpdateSubmission/UpdateSubmission.types';
import { StyledButton } from '../../../../../../common/Styleguide/Common/Button';

function gameLoaderTypeOptions(isGameJam: boolean) {
  const loaders = RECOMMENDED_LOADER_TYPES.map((key) => {
    return {
      label: gameLoaderTypeToName(key),
      value: key,
    };
  });
  if (isGameJam) {
    return loaders.filter((loader) => loader.value !== 'iframe');
  }
  return loaders;
}

function moreGameLoaderTypeOptions(isGameJam: boolean) {
  const loaders = NON_RECOMMENDED_LOADER_TYPES.map((key) => {
    return {
      label: gameLoaderTypeToName(key),
      value: key,
    };
  });
  if (isGameJam) {
    return loaders.filter((loader) => loader.value !== 'iframe');
  }
  return loaders;
}

interface TypeSelectProps {
  gameLoaderType?: GameLoaderType;
  disabled?: boolean;
  onGameLoaderTypeChange: (newValue: GameLoaderType) => void;
  error?: boolean;
  isGameJam?: boolean;
}

export const TypeSelect: React.FunctionComponent<TypeSelectProps> = ({
  onGameLoaderTypeChange,
  gameLoaderType,
  disabled,
  error = false,
  isGameJam = false,
}) => {
  const [showMoreLoaderTypeOptions, setShowMoreLoaderTypeOptions] = React.useState(
    gameLoaderType ? NON_RECOMMENDED_LOADER_TYPES.includes(gameLoaderType) : false,
  );

  const toggleShowMoreLoaderTypeOptions = (event: React.MouseEvent) => {
    // Prevent event from propagating to avoid closing the dropdown
    event.stopPropagation();
    setShowMoreLoaderTypeOptions(!showMoreLoaderTypeOptions);
  };

  return (
    <>
      <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
        Game Type <span>*</span>
      </StyledBodyText>
      <CustomSelect
        value={gameLoaderType || ''}
        placeholder="Select"
        error={error}
        disabled={disabled}
        onChange={(_e: any, newValue: any) => {
          onGameLoaderTypeChange(newValue);
        }}
        style={{ width: HALF_INPUT_WIDTH }}
        maxHeight={'500px'}
      >
        {gameLoaderTypeOptions(isGameJam).map((option) => {
          return (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          );
        })}
        {moreGameLoaderTypeOptions(isGameJam).map((option) => {
          return (
            <Option
              key={option.value}
              value={option.value}
              style={{
                display: showMoreLoaderTypeOptions ? 'block' : 'none',
              }}
            >
              {option.label}
            </Option>
          );
        })}
        <Option value="showMoreLoaders">
          <StyledButton onClick={toggleShowMoreLoaderTypeOptions}>
            {showMoreLoaderTypeOptions ? 'Show less options' : 'Show more options'}
          </StyledButton>
        </Option>
      </CustomSelect>
      {gameLoaderType && UNITY_GAME_LOADERS.includes(gameLoaderType) && (
        <StyledBodyText color="white30" variant="bodyLower">
          These{' '}
          <a href={`${DOCS_URL}/sdk/unity/resources/export-tips/`} target="_blank" rel="noreferrer">
            Unity WebGL export tips
          </a>{' '}
          may help you optimize your game, thus increasing your revenue.
        </StyledBodyText>
      )}
    </>
  );
};

export default TypeSelect;
