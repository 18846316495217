import { HasSize, UploadedFile } from '../../../../../../common/domain/upload';
import {
  GameLoaderType,
  MOBILE_FRIENDLY_LOADERS,
  UNITY_GAME_LOADERS,
  unityLoadersWithDataSave,
} from '../../../../../../common/domain/game';
import { isTooLongStringInput, isValidUrl } from '../../../../../../common/validations';
import { Unity56Encoding } from './UnityLoaderOptions/UnityCompressionSelect';
import { BaseFormProblem, BaseFormSoftWarnings } from '../../SubmitGame/submission-errors';
import { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import isEmptyString from '../../../../../../common/is-empty-string';
import slugify, { isValidSlug } from '../../../../../../common/slug';

export const MAX_NO_TAGS = 5;

// developer cannot submit if total size > 250MB or if there are more than 1500 files, however these hard warnings aren't applied when updating
export const MAX_TOTAL_FILE_SIZE_MB = 250;
export const MAX_TOTAL_FILES_COUNT = 1500;

// these are for the soft warnings (yellow warnings), that allow you to submit
export const TOTAL_FILE_SIZE_MOBILE_WARNING_MB = 20;
export const TOTAL_FILE_SIZE_DESKTOP_WARNING_MB = 50;
export const TOTAL_FILE_COUNT_WARNING = 200;
export const MAX_LOBBY_SIZE = 999;
export const MIN_LOBBY_SIZE = 2;

export type ProgressSaveType =
  | 'UNKNOWN' // old submissions without completed data save toggles
  | 'PLAYERPREFS' // unity submissions storing data just in player prefs
  | 'CUSTOM' // unity submission, storing data in a custom file. This will also ask the devs for a file name.
  | 'LOCALSTORAGE' // html5 submissions storing data in localStorage
  | 'NOTNEEDED' // The game does not need progress save
  | 'NO' // the game doesn't store any progress
  | 'INGAMEAUTOLOGIN' // same thing as INGAME, but also offers auto login
  | 'SDKPS'; // for HTML5 games that use the data module from our HTML5 SDK to store data

export interface MultiplayerOptions {
  isMultiplayer?: boolean;
  supportsInstantJoin?: boolean;
  hasModeratedChat?: boolean;
  minLobbySize?: number;
  maxLobbySize?: number;
}

export interface BaseFormData {
  name: string | null;
  gameLoaderType: GameLoaderType | null;
  isFullscreenable: boolean;
  isIOSFriendly: boolean;
  isAndroidFriendly: boolean;
  orientation: GAME_ORIENTATION | null;
  iframeLink: string | null;
  files: UploadedFile[] | null;
  uploadInProgress: boolean;
  unity56Encoding?: Unity56Encoding;
  unitySaveFileName?: string;
  progressSaveType?: ProgressSaveType;
  hasIAP: boolean;
  isChromebookFriendly: boolean;
  multiplayerOptions?: MultiplayerOptions;
  isGameJam?: boolean;
}

export function buildDefaultBaseFormData(): BaseFormData {
  return {
    name: null,
    gameLoaderType: null,
    isFullscreenable: false,
    isIOSFriendly: false,
    isAndroidFriendly: false,
    orientation: null,
    iframeLink: null,
    files: null,
    uploadInProgress: false,
    unity56Encoding: 'brotli',
    progressSaveType: 'UNKNOWN',
    hasIAP: false,
    isChromebookFriendly: false,
    multiplayerOptions: undefined,
    isGameJam: false,
  };
}

export function validateBaseForm(form: BaseFormData, validateFileLimits?: boolean): BaseFormProblem[] {
  const problems: BaseFormProblem[] = [
    ...validateName(form),
    ...validateGameLoaderType(form),
    ...validateOrientation(form),
    ...validateMultiplayerLobbySize(form.multiplayerOptions),
    ...validateGameJam(form),
  ];

  if (validateFileLimits) {
    problems.push(...validateFileLimitsHard(form.files || []));
  }

  return problems;
}
function validateName(form: BaseFormData): BaseFormProblem[] {
  const { name } = form;
  if (isEmptyString(name)) {
    return ['NAME_MISSING'];
  }
  const trimmed = (name as string).trimLeft();
  const slug = slugify(trimmed);
  if (!isValidSlug(slug)) {
    return ['NAME_INVALID'];
  }
  return [];
}

export function validateOrientation(form: BaseFormData): BaseFormProblem[] {
  const { gameLoaderType, isAndroidFriendly, isIOSFriendly, orientation } = form;

  if (!gameLoaderType || !MOBILE_FRIENDLY_LOADERS.includes(gameLoaderType)) {
    return [];
  }

  if (!isAndroidFriendly && !isIOSFriendly) {
    return [];
  }

  if (!orientation) {
    return ['ORIENTATION_MISSING'];
  }

  return [];
}

export function validateFileLimitsSoft(mobileFriendly: boolean, files: HasSize[]): BaseFormSoftWarnings[] {
  const warnings: BaseFormSoftWarnings[] = [];
  const totalSize = files.reduce((acc, file) => acc + file.size, 0);

  if (files.length > TOTAL_FILE_COUNT_WARNING) {
    warnings.push('FILE_COUNT_TOO_BIG');
  }

  if (mobileFriendly && totalSize > TOTAL_FILE_SIZE_MOBILE_WARNING_MB * 1024 * 1024) {
    warnings.push('TOTAL_FILE_SIZE_TOO_BIG_MOBILE');
  }

  if (!mobileFriendly && totalSize > TOTAL_FILE_SIZE_DESKTOP_WARNING_MB * 1024 * 1024) {
    warnings.push('TOTAL_FILE_SIZE_TOO_BIG_DESKTOP');
  }

  return warnings;
}

export function validateFileLimitsHard(files: HasSize[]): BaseFormProblem[] {
  const problems: BaseFormProblem[] = [];
  const totalSize = files.reduce((acc, file) => acc + file.size, 0);

  if (files.length > MAX_TOTAL_FILES_COUNT) {
    problems.push('TOTAL_FILE_COUNT_EXCEEDED');
  }

  if (totalSize > MAX_TOTAL_FILE_SIZE_MB * 1024 * 1024) {
    problems.push('TOTAL_FILE_SIZE_EXCEEDED');
  }

  return problems;
}

function validateMultiplayerLobbySize(multiplayerOptions: MultiplayerOptions | undefined): BaseFormProblem[] {
  const problems: BaseFormProblem[] = [];

  // if at least one of the lobby size options is set, both must be correctly set
  if (
    (multiplayerOptions?.minLobbySize !== undefined && multiplayerOptions?.minLobbySize !== null) ||
    (multiplayerOptions?.maxLobbySize !== undefined && multiplayerOptions?.maxLobbySize !== null)
  ) {
    if (
      typeof multiplayerOptions?.minLobbySize !== 'number' ||
      typeof multiplayerOptions?.maxLobbySize !== 'number' ||
      multiplayerOptions?.maxLobbySize < multiplayerOptions?.minLobbySize
    ) {
      problems.push('MULTIPLAYER_INVALID_LOBBY');
    }

    if (typeof multiplayerOptions?.minLobbySize === 'number' && multiplayerOptions?.minLobbySize < MIN_LOBBY_SIZE) {
      problems.push('MULTIPLAYER_INVALID_LOBBY_MIN');
    }

    if (typeof multiplayerOptions?.maxLobbySize === 'number' && multiplayerOptions.maxLobbySize > MAX_LOBBY_SIZE) {
      problems.push('MULTIPLAYER_INVALID_LOBBY_MAX');
    }
  }
  return problems;
}

function validateGameLoaderType(form: BaseFormData): BaseFormProblem[] {
  const { gameLoaderType, uploadInProgress } = form;

  if (gameLoaderType === null) {
    return ['GAME_LOADER_TYPE_MISSING'];
  }

  if (gameLoaderType === 'iframe') {
    return validateIframeForm(form);
  }

  if (uploadInProgress) {
    return ['GAME_FILE_UPLOAD_IN_PROGRESS'];
  }

  if (gameLoaderType === 'html5') {
    return validateHTML5Form(form);
  }

  if (UNITY_GAME_LOADERS.includes(gameLoaderType)) {
    return validateUnityForm(form);
  }

  throw new Error(`Unmapped game loader type: ${gameLoaderType}`);
}

function validateHTML5Form(form: BaseFormData): BaseFormProblem[] {
  const { files } = form;
  const problems: BaseFormProblem[] = [];
  if (!files || files.length < 1 || !hasFilePattern(files, /\/?index.html$/)) {
    problems.push('HTML5_FILES_MISSING');
  }
  if (!form.progressSaveType || form.progressSaveType === 'UNKNOWN') {
    problems.push('APS_INFO_MISSING');
  }
  return problems;
}

function validateIframeForm(form: BaseFormData): BaseFormProblem[] {
  const { iframeLink } = form;
  const problems: BaseFormProblem[] = [];
  if (iframeLink === null || isTooLongStringInput(iframeLink) || !isValidUrl(iframeLink)) {
    problems.push('IFRAME_INVALID_URL');
  }
  if (!form.progressSaveType || form.progressSaveType === 'UNKNOWN') {
    problems.push('APS_INFO_MISSING');
  }
  return problems;
}

function validateUnityForm(form: BaseFormData): BaseFormProblem[] {
  const { files } = form;
  const problems: BaseFormProblem[] = [];
  if (!files || files.length < 1) {
    problems.push('UNITY_FILES_MISSING');
  }
  if (form.gameLoaderType && unityLoadersWithDataSave.includes(form.gameLoaderType)) {
    if (!form.progressSaveType || form.progressSaveType === 'UNKNOWN') {
      problems.push('APS_INFO_MISSING');
    }
    if (form.progressSaveType === 'CUSTOM') {
      if (!form.unitySaveFileName || form.unitySaveFileName?.length === 0) {
        problems.push('APS_UNITY_CUSTOM_NAME_MISSING');
      } else if (isTooLongStringInput(form.unitySaveFileName)) {
        problems.push('APS_UNITY_CUSTOM_NAME_INVALID');
      }
    }
  }
  return problems;
}

function validateGameJam(form: BaseFormData): BaseFormProblem[] {
  const { isGameJam } = form;
  // We currently have no specific requirements for game jam submissions.
  if (isGameJam) {
    return [];
  }
  return [];
}

function hasFilePattern(files: UploadedFile[], rx: RegExp) {
  return !!files.find(({ path }) => rx.test(path));
}
