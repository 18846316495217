import React from 'react';
import { BaseFormProblem, BaseFormSoftWarnings, DetailsFormProblem } from '../../SubmitGame/submission-errors';
import {
  BaseFormData,
  validateBaseForm,
  buildDefaultBaseFormData,
  validateFileLimitsHard,
  validateFileLimitsSoft,
} from '../GameBaseForm/baseFormHelper';
import { DetailsFormData, validateDetailsForm, buildDefaultDetailsFormData } from '../GameDetailsForm/detailsFormHelper';
import { RevenueShareFormData, buildDefaultRevenueShareFormData, validateRevenueShareForm } from '../RevenueShareForm/RevenueShareFormData';
import { Submission } from '../submission-graphql';
import { convertGameCoversArrayToObject } from '../../../../../../common/graphql/games/game-covers-helper';
import { NON_RELEASED_STATUSES } from '../UpdateSubmission/UpdateSubmission.types';
import cleanCopy from '../../../../../../common/graphql/clean-copy';
import { isGameJamTime } from '../gamejamHelper';
import { HasSize } from '../../../../../../common/domain/upload';

interface SubmissionProviderData {
  detailsForm: DetailsFormData;
  baseForm: BaseFormData;
  detailsFormProblems?: DetailsFormProblem[];
  baseFormProblems?: BaseFormProblem[]; // problems of the files already uploaded
  uploadingFilesSoftProblems?: BaseFormSoftWarnings[]; // soft problems of all the files, including those currently being uploaded
  uploadingFilesHardProblems?: BaseFormProblem[]; // hard problems of all the files, including those currently being uploaded
  attemptedToSave?: boolean;
  submission?: Submission;
  revalidateByQA?: boolean;
  revenueShareFormValid?: boolean;
  revenueShareForm: RevenueShareFormData;
  isNonEditable: boolean;
  gameJamLocked: boolean;
  isReleased: boolean;
  modified: boolean;
  /** Validate files size/count limits only for new submissions, so that existing ones can still update without any troubles.  */
  validateFileLimits: boolean;
}

export interface SubmissionContextData extends SubmissionProviderData {
  updateDetailsForm: (detailsForm: DetailsFormData) => void;
  updateBaseForm: (baseForm: BaseFormData) => void;
  onFilesToUploadSet: (baseForm: HasSize[]) => void;
  updateRevenueShareForm: (revenueShareForm: RevenueShareFormData) => void;
  setAttemptedToSave: (attemptedToSave: boolean) => void;
  setIsModified: (isModified: boolean, onSet?: () => void) => void;
  setBuildId: (buildId: string, callback?: () => void) => void;
}

export const SubmissionContext = React.createContext<SubmissionContextData>({
  detailsForm: buildDefaultDetailsFormData(),
  baseForm: buildDefaultBaseFormData(),
  revenueShareForm: buildDefaultRevenueShareFormData(),
  detailsFormProblems: [],
  baseFormProblems: [],
  uploadingFilesSoftProblems: [],
  uploadingFilesHardProblems: [],
  attemptedToSave: false,
  revenueShareFormValid: false,
  revalidateByQA: false,
  isNonEditable: false,
  isReleased: false,
  modified: false,
  validateFileLimits: false,
  gameJamLocked: false,
  updateDetailsForm: () => {},
  updateBaseForm: () => {},
  onFilesToUploadSet: () => {},
  updateRevenueShareForm: () => {},
  setAttemptedToSave: () => {},
  setIsModified: () => {},
  setBuildId: () => {},
});

interface SubmissionProviderProps {
  /** Initial submission is present when editing a submission. It's absence means this is a new submission */
  initialSubmission?: Submission;
  children?: React.ReactNode;
}

interface SubmissionState extends SubmissionContextData {}

class SubmissionProvider extends React.Component<SubmissionProviderProps, SubmissionState> {
  constructor(props: SubmissionProviderProps) {
    super(props);
    const { initialSubmission } = this.props;

    const detailsForm: DetailsFormData = initialSubmission
      ? {
          category: initialSubmission.categoryId,
          tags: initialSubmission.tagIds,
          description: initialSubmission.description,
          controls: initialSubmission.controls,
          playUrl: initialSubmission.playStoreLink,
          appstoreUrl: initialSubmission.appStoreLink,
          steamUrl: initialSubmission.steamStoreLink,
          playStoreDownloads: initialSubmission.playStoreDownloads ? `${initialSubmission.playStoreDownloads}` : null,
          appStoreDownloads: initialSubmission.appStoreDownloads ? `${initialSubmission.appStoreDownloads}` : null,
          steamDownloads: initialSubmission.steamDownloads ? `${initialSubmission.steamDownloads}` : null,
          gameCovers: convertGameCoversArrayToObject(initialSubmission.gameCovers),
        }
      : buildDefaultDetailsFormData();

    const baseForm: BaseFormData = initialSubmission
      ? ({
          name: initialSubmission.gameName,
          gameLoaderType: initialSubmission.gameLoaderType,
          isFullscreenable: initialSubmission.fullscreenable,
          isIOSFriendly: !!initialSubmission.isIOSFriendly,
          isAndroidFriendly: !!initialSubmission.isAndroidFriendly,
          orientation: initialSubmission.orientation,
          iframeLink: initialSubmission.iframeLink,
          files: initialSubmission.gameFiles,
          uploadInProgress: false,
          unity56Encoding: initialSubmission.unity56Encoding,
          // the frontend for now handles only a single file name, however the backend stores an array
          unitySaveFileName: initialSubmission.unitySaveFileNames?.[0],
          progressSaveType: initialSubmission.apsDetail?.progressType,
          hasIAP: initialSubmission.hasIAP || false,
          isChromebookFriendly: initialSubmission.isChromebookFriendly || false,
          multiplayerOptions: initialSubmission.multiplayerOptions ? cleanCopy(initialSubmission.multiplayerOptions, []) : undefined,
          isGameJam: initialSubmission.isGameJam,
        } as BaseFormData)
      : buildDefaultBaseFormData();

    const validateFileLimits = !initialSubmission;

    const gameJamLocked = !!initialSubmission?.isGameJam && !isGameJamTime();

    this.state = {
      revalidateByQA: initialSubmission?.requiresDevQaCheck || false,
      detailsFormProblems: validateDetailsForm(detailsForm),
      baseFormProblems: validateBaseForm(baseForm, validateFileLimits),
      revenueShareFormValid: false,
      submission: initialSubmission,
      revenueShareForm: buildDefaultRevenueShareFormData(),
      detailsForm: detailsForm,
      baseForm: baseForm,
      isNonEditable: initialSubmission?.status === 'REJECTED' || gameJamLocked,
      gameJamLocked: gameJamLocked,
      isReleased: initialSubmission ? !NON_RELEASED_STATUSES.includes(initialSubmission.status) : false,
      modified: false,
      validateFileLimits,
      updateDetailsForm: this.updateDetailsForm,
      updateBaseForm: this.updateBaseForm,
      onFilesToUploadSet: this.onFilesToUploadSet,
      updateRevenueShareForm: this.updateRevenueShareForm,
      setAttemptedToSave: this.setAttemptedToSave,
      setIsModified: this.setIsModified,
      setBuildId: this.setBuildId,
    };
  }

  render() {
    return <SubmissionContext.Provider value={this.state}>{this.props.children}</SubmissionContext.Provider>;
  }

  private setAttemptedToSave = (attemptedToSave: boolean) => {
    this.setState({ attemptedToSave });
  };
  private setIsModified = (isModified: boolean, onSet?: () => void) => {
    this.setState({ modified: isModified }, onSet);
  };

  private updateDetailsForm = (detailsForm: DetailsFormData) => {
    const detailsFormProblems = validateDetailsForm(detailsForm);
    this.setState({ detailsForm: detailsForm, detailsFormProblems: detailsFormProblems, modified: true });
  };

  private updateBaseForm = (baseForm: BaseFormData) => {
    const baseFormProblems = validateBaseForm(baseForm, this.state.validateFileLimits);
    const needToReValidateByQA = this.needToReValidateByQA(baseForm);
    this.setState({ baseForm: baseForm, baseFormProblems: baseFormProblems, modified: true, revalidateByQA: needToReValidateByQA });
  };

  private onFilesToUploadSet = <T extends HasSize>(files: T[]) => {
    const softLimitsProblems = validateFileLimitsSoft(this.state.baseForm.isAndroidFriendly ?? this.state.baseForm.isIOSFriendly, files);
    const hardLimitsProblems = this.state.validateFileLimits ? validateFileLimitsHard(files || []) : [];

    this.setState({
      uploadingFilesSoftProblems: softLimitsProblems,
      uploadingFilesHardProblems: hardLimitsProblems,
    });
  };

  private updateRevenueShareForm = (revenueShareForm: RevenueShareFormData) => {
    const revenueShareFormValid = validateRevenueShareForm(revenueShareForm).length === 0;
    this.setState({ revenueShareForm, revenueShareFormValid });
  };

  private needToReValidateByQA(newForm: BaseFormData): boolean {
    const initialSubmission = this.props.initialSubmission;
    if (!initialSubmission || this.props.initialSubmission?.requiresDevQaCheck) {
      return true;
    }
    if (initialSubmission.gameLoaderType !== newForm.gameLoaderType) {
      return true;
    }
    if (initialSubmission.iframeLink !== newForm.iframeLink) {
      return true;
    }
    if (initialSubmission.unity56Encoding !== newForm.unity56Encoding) {
      return true;
    }
    if (initialSubmission.isIOSFriendly !== newForm.isIOSFriendly) {
      return true;
    }
    if (initialSubmission.isAndroidFriendly !== newForm.isAndroidFriendly) {
      return true;
    }
    if (initialSubmission.fullscreenable !== newForm.isFullscreenable) {
      return true;
    }
    if (initialSubmission.orientation !== newForm.orientation) {
      return true;
    }
    if (initialSubmission.hasIAP !== newForm.hasIAP) {
      return true;
    }
    if (initialSubmission.isChromebookFriendly !== newForm.isChromebookFriendly) {
      return true;
    }
    if (initialSubmission.apsDetail?.progressType !== newForm.progressSaveType) {
      return true;
    }
    if (
      initialSubmission.unitySaveFileNames &&
      initialSubmission.unitySaveFileNames.length > 0 &&
      initialSubmission.unitySaveFileNames[0] !== newForm.unitySaveFileName
    ) {
      return true;
    }
    const previousFiles = initialSubmission.gameFiles;
    const nextFiles = newForm.files;
    if (previousFiles === null && nextFiles === null) {
      return false;
    }
    if ((previousFiles === null && nextFiles !== null) || (previousFiles !== null && nextFiles === null)) {
      return true;
    }
    const previousIds = previousFiles!.map((file) => file.uploadId).sort();
    const nextIds = nextFiles!.map((file) => file.uploadId).sort();
    return JSON.stringify(previousIds) !== JSON.stringify(nextIds);
  }

  private setBuildId = (buildId: string, callback?: () => void) => {
    this.setState(
      (prevState) => ({
        submission: prevState.submission && {
          ...prevState.submission,
          gameBuildId: buildId,
        },
      }),
      callback,
    );
  };
}

export default SubmissionProvider;
